<template>
  <div class="main_container">
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Ajout de transaction
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row my-5">
      <form class="col-md-10 mx-auto">
        <div class="form-group row">
          <div class="col-md-6">

            <label class="req">Département cible:</label>
            <select class="form-control"
                    v-model="form.departement">
              <option value="">Choisissez un Département</option>
              <option value="CONTROLE_GESTION">Controle de gestion</option>
              <option value="COMPTABILITE">Comptabilité</option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="req">Base:</label>
            <select class="form-control"
                    v-model="form.base">
              <option value="">Choisissez une base</option>
              <option value="SIEGE">SIEGE</option>
              <option value="PEAGE">PEAGE</option>
              <option value="PESAGE">PESAGE</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label class="req">Compte comptable:</label>
            <select class="form-control"
                    v-model="form.compte"
                    v-if="planComptes.donnees">
              <option value="">Choisissez un numéro de compte comptable</option>
              <option v-for="(plan, plkey) in planComptes.donnees"
                      :key="plkey"
                      :value="plan.id">{{plan.compte}} - {{plan.description}}</option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Banque:</label>
            <select class="form-control"
                    v-model="form.compte_bancaire"
                    v-if="banks.length>0">
              <option value="">Choisissez une banque</option>
              <optgroup v-for="(banque,bakey) in banks"
                        :key="bakey"
                        :label="banque.denomination + '(' + banque.sigle+')'">
                <option v-for="(compte, cokey) in banque.compte_bancaires"
                        :key="cokey"
                        :value="compte.id">{{compte.numero}}</option>
              </optgroup>
            </select>
          </div>
        </div>
        <div class="form-group row"
             v-if="form.departement=='CONTROLE_GESTION'">
          <div class="col-md-6">
            <label for="">Budget annuel:</label>
            <input type="text"
                   class="form-control"
                   v-model="budget_annuel"
                   @input="addSpace('budget_annuel')">
          </div>
          <div class="col-md-6">
            <label for="">Découpage mensuel du budget:</label>
            <select class="form-control"
                    v-model="opt"
                    @change="linearMonth()">
              <option value="">Choisissez une option</option>
              <option value="1">Linéaire</option>
              <option value="2">Manuelle</option>
            </select>
          </div>
        </div>
        <div class="form-group row"
             v-if="opt=='2'">
          <div class="col-md-1"
               v-for="(budget, budgkey) in budget_mensuel"
               :key="budgkey">
            <label for="">{{budget.mois}}</label>
            <input type="number" 
                   class="form-control"
                   min="0"
                   max="1"
                   v-model="budget.valeur"
                   @change="verifMois()">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label class="req">Date:</label>
            <input type="date"
                   class="form-control"
                   v-model="form.date">
          </div>
          <div class="col-md-6">
            <label for="">Libellé opération:</label>
            <input type="text"
                   class="form-control"
                   v-model="form.libelle">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">

            <label class="req">Type</label>
            <select class="form-control"
                    name=""
                    id=""
                    v-model="form.type"
                    @change="getSousType()">
              <option value="">Choisissez un type</option>
              <option value="REVENUS">REVENUS</option>
              <option value="CHARGES">CHARGES</option>
              <option value="LEVEE_FONDS">LEVEE DE FONDS</option>
            </select>
            <span v-if="form.errors().has('type')"
                  v-text="form.errors().get('type')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-6">
            <label class="req">Sous-type</label>
            <select v-if="form.type == 'LEVEE_FONDS'"
                    class="form-control"
                    name=""
                    id=""
                    v-model="form.sous_type">
              <option value="">Choisissez un sous-type</option>
              <option value="LEVEE_FONDS">LEVEE DE FONDS</option>
            </select>
            <select v-else-if="sousTypeList.length >0"
                    class="form-control"
                    name=""
                    id=""
                    v-model="form.sous_type">
              <option value="">Choisissez un sous-type</option>
              <option v-for="(soustype,stkey) in sousTypeList"
                      :key="stkey"
                      :value="soustype.sous_type">{{soustype.sous_type}}</option>
            </select>
            <select v-else
                    class="form-control"
                    name=""
                    id=""
                    v-model="form.sous_type">
              <option value="">Choisissez un sous-type</option>
            </select>
            <span v-if="form.errors().has('sous_type')"
                  v-text="form.errors().get('sous_type')"
                  class="errorMsg"> </span>
          </div>
        </div>
        <div class="form-group row"
             v-if="form.departement=='COMPTABILITE'">
          <div class="col-md-6">
            <label class="req">Débit:</label>
            <input type="text"
                   class="form-control"
                   v-model="debit"
                   @input="addSpace('debit')">
          </div>
          <div class="col-md-6">
            <label class="req">Crédit:</label>
            <input type="text"
                   class="form-control"
                   v-model="credit"
                   @input="addSpace('credit')">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label for="">Référence</label>
            <input class="form-control"
                   type="text"
                   name=""
                   id=""
                   v-model="form.reference" />
            <span v-if="form.errors().has('reference')"
                  v-text="form.errors().get('reference')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-6">
            <label for="">Commentaires:</label>
            <input class="form-control"
                   type="text"
                   name=""
                   id=""
                   v-model="form.commentaire" />
            <span v-if="form.errors().has('commentaires')"
                  v-text="form.errors().get('commentaires')"
                  class="errorMsg"> </span>
          </div>
        </div>
        
        <div class="form-group row"
             v-if="form.sous_type=='DEPENSES_PEAGE' || form.sous_type=='RECETTES_PEAGE'">
          <div class="col-md-6">
            <label class="req">Gare:</label>
            <select class="form-control"
                    v-model="form.gare_peage">
              <option value="">Choisissez une gare</option>
              <option value="ATTINGUE">ATTINGUE</option>
              <option value="MOAPE">MOAPE</option>
              <option value="SINGROBO">SINGROBO</option>
              <option value="THOMASSET">THOMASSET</option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="req">Classes de véhicule:</label>
            <select class="form-control"
                    v-model="form.classe_vehicule">
              <option value="">Choisissez une classe</option>
              <option value="classe 1">classe 1</option>
              <option value="classe 2">classe 2</option>
              <option value="classe 3">classe 3</option>
              <option value="classe 4">classe 4</option>
              <option value="classe 5">classe 5</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6"
               v-if="form.sous_type=='DEPENSES_PESAGE' || form.sous_type=='REDEVANCES_PESAGE'">
            <label class="req">Gare:</label>
            <select class="form-control"
                    v-model="form.gare_pesage">
              <option value="">Choisissez une base</option>
              <option value="ABENGOUROU">ABENGOUROU</option>
              <option value="ALLOKOI">ALLOKOI</option>
              <option value="ANYAMA">ANYAMA</option>
              <option value="BONOUA">BONOUA</option>
              <option value="DIVO">DIVO</option>
              <option value="OUANGOLO">OUANGOLO</option>
              <option value="SAN-PEDRO">SAN-PEDRO</option>
              <option value="YAKRO">YAKRO</option>
              <option value="ZINZENOU">ZINZENOU</option>
            </select>
          </div>
          <div class="col-md-6"
               v-if="form.sous_type=='LEVEE_FONDS'">
            <label class="req">Levée de fond:</label>
            <select class="form-control"
                    v-model="form.levee_fond">
              <option value="">Choisissez une levée de fond</option>
              <option v-for="(levee,lekey) in leveefond.donnees"
                      :key="lekey"
                      :value="levee.id">{{levee.objet_dette}}</option>
            </select>
          </div>
        </div>
        <div class="button_form">
          <button type="button"
                  class="btn btn-f-blue enter_btn"
                  @click='submit'>
            <i class="flaticon-floppy-disk"></i> Enregistrer
          </button>
          <button type="reset"
                  class="btn btn-f-blue cancle_btn ml-4">
            <i class="flaticon-cancel"></i> Annuler
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import form from "vuejs-form"

export default ({
  name:"AddTransaction",
  components:{
    Notif
  },
  data:()=>({
    form:form({
      "base": "",
      "compte": "",
      "compte_bancaire": "",
      "debit" : 0,
      "credit": 0,
      "type" : "",
      "sous_type": "",
      "date" :"",
      "libelle":"",
      "commentaire":"",
      "reference":"",
      "tranche_levee_fond":"",
      "levee_fond":"",
      "zone_gare":"",
      "gare_peage":"",
      "classe_vehicule":"",
      "gare_pesage":"",
      "departement":"",
      "budget_annuel":"",
      "realisation":"",
      "jan":"",
      "fev":"",
      "mars":"",
      "avr":"",
      "mai":"",
      "juin":"",
      "juil":"",
      "aout":"",
      "sept":"",
      "oct":"",
      "nov":"",
      "dec":"",
    }).rules({
      "base": "required",
      // "compte": "required",
      // "compte_bancaire": "required",
      "debit" : "required",
      "credit": "required",
      "type" : "required",
      "sous_type": "required",
      "date" :"required",
    }).messages({
      "base": "Veuillez renseigner le champ base",
      // "compte": "Veuillez renseigner le champ compte",
      // "compte_bancaire": "Veuillez renseigner le champ compte bancaire",
      "debit" : "Veuillez renseigner le champ débit",
      "credit": "Veuillez renseigner le champ crédit",
      "type" : "Veuillez renseigner le champ type",
      "sous_type": "Veuillez renseigner le champ sous_type",
      "date" :"Veuillez renseigner le champ date",
    }),
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    banks:[],
    sousTypeList:"",
    opt:"",
    debit:0,
    credit:0,
    budget_annuel:0,
    budget_mensuel:Array(
      {mois:"jan",valeur:0},
      {mois:"fev",valeur:0},
      {mois:"mars",valeur:0},
      {mois:"avr",valeur:0},
      {mois:"mai",valeur:0},
      {mois:"juin",valeur:0},
      {mois:"juil",valeur:0},
      {mois:"aout",valeur:0},
      {mois:"sept",valeur:0},
      {mois:"oct",valeur:0},
      {mois:"nov",valeur:0},
      {mois:"dec",valeur:0}
    )
  }),
  watch:{
    banques(){
      if (this.banques.donnees) {
        this.banks=[]
        this.banques.donnees.forEach(banque => {
          if (banque.compte_bancaires.length>0) {
            this.banks.push(banque)
          }
        })
        // console.log(this.banks)
      }
    },
    msgSuccessComptabiliteTransaction(){
      if (this.msgSuccessComptabiliteTransaction) {
        this.notif.message = "transaction enregistrée avec succès"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgSuccessComptabiliteTransaction("")
            this.$router.push({ name: "ListeDesTransactions" })
          }.bind(this),
          5000
        )
      }
    },
    msgFailComptabiliteTransaction(){
      if (this.msgFailComptabiliteTransaction) {
        this.notif.message = this.msgFailComptabiliteTransaction
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailComptabiliteTransaction("")
          }.bind(this),
          5000
        )
      }
    },
  },
  created(){
    this.leveeFonds()
    this.loadBanques()
    this.loadTypeTransactions()
    this.getListPlanComptes()
  },
  computed:{
    ...mapGetters(["msgSuccessComptabiliteTransaction","msgFailComptabiliteTransaction","leveefond","banques","typeTransactions","planComptes"])
  },
  methods:{
    ...mapActions(["postComptabiliteTransaction","leveeFonds","loadBanques","loadTypeTransactions","getListPlanComptes"]),
    ...mapMutations(["setMsgSuccessComptabiliteTransaction","setMsgFailComptabiliteTransaction"]),
    getSousType(){
      this.sousTypeList=""
      this.typeTransactions.forEach(type => {
        console.log(type)
        if (this.form.type == type.type) {
          this.sousTypeList=type.sous_types
        }
      })
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    submit() {
      this.budget_mensuel.forEach(element => {
        this.form[element.mois]=element.valeur
      })
      console.log(this.form.data)
      if (!this.form.validate().errors().any()) {
        this.postComptabiliteTransaction(this.form.data)
      }else{
        this.notif.message = "Veuillez remplir tous les champs requis"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          5000
        )
      }
    },
    linearMonth(){
      if (this.opt=='1') {
        for (let index = 0; index < this.budget_mensuel.length; index++) {
          this.budget_mensuel[index].valeur=0.083
        }
      }else{
        for (let index = 0; index < this.budget_mensuel.length; index++) {
          this.budget_mensuel[index].valeur=0
        }
      }
    },
    verifMois(){
      var total=0
      this.budget_mensuel.forEach(mois => {
        total+=Number(mois.valeur)
        console.log(total)
      })
      if (total>1) {
        this.notif.message = "Le total des pourcentages ne doit pas dépasser 100%"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    }
  }  
})
</script>
